import { FC, useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L, { MarkerCluster } from 'leaflet';

import { api } from '@shared/libs/api';
import { IBlockData, INodeItem, QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

import '@elfalem/leaflet-curve';
import './leaflet.config';

import config from './const';

import 'leaflet/dist/leaflet.css';
import styles from './styles.module.scss';

interface IMarker {
  id: number;
  name: string;
  icon: L.Icon;
  position: {
    lat: number;
    lng: number;
  };
}
export const NodePointMap: FC = () => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [lines, setLines] = useState<L.Curve[]>([]);
  const { data: nodesData } = useQuery({
    queryFn: () => api.getNodes(),
    queryKey: [QueryKey.Nodes],
    refetchInterval: 5000,
  });
  const { data: blocksData } = useQuery({
    queryFn: () =>
      api.getBlocksList({
        page: 1,
        pageSize: 5,
      }),
    queryKey: [QueryKey.BlocksList],
    refetchInterval: 5000,
  });

  const clearLines = () => {
    for (const value of lines) {
      map?.removeLayer(value);
    }
    setLines([]);
  };
  const updateNodePoints = (nodes: INodeItem[], blocks: IBlockData[]) => {
    const redIcon = new L.Icon(config.redIcon);
    const bounds: [number, number][] = [];
    const newMarkers: IMarker[] = [];

    clearLines();

    if (!blocks[0]) {
      return;
    }
    if (nodes.length === 0) {
      return;
    }
    let index = 0;

    for (const node of nodes) {
      index++;
      const ip = node?.address;
      const lat = +node?.latitude;
      const lon = +node?.longitude;

      if (!ip || !lat || !lon) {
        continue;
      }

      newMarkers.push({
        icon: redIcon,
        id: index,
        name: ip,
        position: {
          lat: lat,
          lng: lon,
        },
      });
      bounds.push([lat, lon]);
    }

    setMarkers(newMarkers);

    map?.fitBounds(L.latLngBounds(bounds), { maxZoom: 13 });
    map?.scrollWheelZoom.disable();
  };

  useEffect(() => {
    if (nodesData && blocksData && map) {
      updateNodePoints(nodesData, blocksData['block-data']);
    }
  }, [nodesData, blocksData]);

  const createClusterCustomIcon = function (cluster: MarkerCluster) {
    return L.divIcon({
      className: styles.nodeMap__markerCluster,
      html: `<span>${cluster.getChildCount()}</span>`,
      iconSize: L.point(50, 50, true),
    });
  };

  return (
    <MapContainer
      ref={setMap}
      className={styles.nodeMap}
      center={config.centerCoords}
      zoom={config.zoom}
      minZoom={config.minZoom}
      zoomControl
      scrollWheelZoom={false}>
      <TileLayer url={config.tileUrl} />
      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createClusterCustomIcon}
        showCoverageOnHover={false}>
        {markers.map((marker, idx) => (
          <Marker key={idx} position={marker.position} icon={marker.icon}>
            <Tooltip content={marker.name} />
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};
