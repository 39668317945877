import React from 'react';
import cx from 'classnames';
import { SearchIcon, Typography } from 'dekube-ui-kit';

import { Skeleton, useTableSort } from '@/shared';

import { TableItemType, TableProps, WithClassName } from './types';

import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TableRow } from './TableRow';
import { TableTd } from './TableTd';
import { TableTh } from './TableTh';

import styles from './styles.module.scss';

export const Table = <T extends Record<string, TableItemType>, SortKeys>({
  className,
  emptySlot,
  headers,
  initSort,
  items,
  loading,
  onSort,
  skeletonCount = 10,
}: WithClassName<TableProps<T, SortKeys>>): JSX.Element => {
  const hasHeader = headers.some((el) => el.title);
  const keyedValue = headers.find((el) => el.key)?.value;

  const { handleSort, sort, sortBy } = useTableSort<SortKeys>(initSort, onSort);

  return (
    <div className={cx(styles.table, className)}>
      <table className={styles.table__wrapper}>
        {hasHeader && (
          <TableHead>
            <TableRow>
              {headers.length > 0 &&
                headers.map((header) => {
                  if (header.hide) {
                    return;
                  }

                  return (
                    <TableTh
                      key={header.value as string}
                      name={header.value as string}
                      width={header?.width}
                      sort={sortBy === header.sortKey ? sort : null}
                      sortable={header?.sortable}
                      className={header.className}
                      onSort={(sort) =>
                        header.sortable &&
                        header.sortKey &&
                        handleSort(header.sortKey, sort)
                      }>
                      {header.title}
                    </TableTh>
                  );
                })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {loading ? (
            <>
              {Array.from({ length: skeletonCount })
                .fill(1)
                .map((_, i) => (
                  <TableRow key={i}>
                    {headers &&
                      headers.map((header, i) => {
                        if (header.hide) {
                          return;
                        }

                        return (
                          <TableTd
                            key={i}
                            fullWidth
                            name={header.value as string}>
                            <Skeleton fullWidth>123456</Skeleton>
                          </TableTd>
                        );
                      })}
                  </TableRow>
                ))}
            </>
          ) : (
            <>
              {items.length > 0 ? (
                items.map((item, i) => (
                  <TableRow key={(item[keyedValue || ''] as string) || i}>
                    {headers &&
                      headers.map((header, i) => {
                        if (header.hide || !item[header.value]) {
                          return;
                        }

                        return (
                          <TableTd
                            key={i}
                            name={header.value as string}
                            className={header.className}>
                            {item[header.value]}
                          </TableTd>
                        );
                      })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableTd colspan={headers.length}>
                    {emptySlot || (
                      <div className={styles.table__empty}>
                        <div className={styles.table__emptyIconWrap}>
                          <SearchIcon
                            height={24}
                            width={24}
                            color="var(--gray-600)"
                          />
                        </div>
                        <Typography variant="m-medium" color="gray-600">
                          Data not found
                        </Typography>
                      </div>
                    )}
                  </TableTd>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </table>
    </div>
  );
};
