import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeIntervalSelector } from '@/features/App';
import { Card, type SelectMenuItem } from '@/shared';
import {
  BlockByDayChart,
  MainStatsNumbers,
  RoundByDayChart,
} from '@widgets/Stats';

import styles from './styles.module.scss';

export const StatsPage: FC = () => {
  const { t } = useTranslation();

  const [blockTimeInterval, setBlockTimeInterval] =
    useState<SelectMenuItem['value']>('');
  const [roundTimeInterval, setRoundTimeInterval] =
    useState<SelectMenuItem['value']>('');

  return (
    <div className={styles.stats}>
      <div className={styles.stats__numbersWrap}>
        <MainStatsNumbers />
      </div>
      <Card
        height={340}
        titlePx={2}
        titlePy={2}
        bottomPx={2}
        bottomPy={2}
        title={t('titles.blockByDay')}
        bottomDescription={t('descriptions.blockByDay')}
        headRightSlot={
          <TimeIntervalSelector
            onChange={(value) => value && setBlockTimeInterval(value)}
          />
        }>
        <BlockByDayChart interval={blockTimeInterval} />
      </Card>
      <Card
        height={340}
        titlePx={2}
        titlePy={2}
        bottomPx={2}
        bottomPy={2}
        title={t('titles.roundByDay')}
        bottomDescription={t('descriptions.roundByDay')}
        headRightSlot={
          <TimeIntervalSelector
            onChange={(value) => value && setRoundTimeInterval(value)}
          />
        }>
        <RoundByDayChart interval={roundTimeInterval} />
      </Card>
    </div>
  );
};
