import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type SkeletonBackgroundColor = 'transparent' | 'default';

interface SkeletonProps {
  absolute?: boolean;
  inline?: boolean;
  className?: string;
  height?: number;
  fullWidth?: boolean;
  bgColor?: SkeletonBackgroundColor;
}
export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  absolute,
  bgColor = 'default',
  children,
  className,
  fullWidth,
  height = 24,
  inline,
}) => {
  const colorClass = () =>
    bgColor === 'transparent'
      ? styles.skeleton__bgColor_transparent
      : styles.skeleton__bgColor_default;

  return (
    <div
      className={cx(
        styles.skeleton,
        absolute && styles.skeleton_absolute,
        inline && styles.skeleton_inline,
        fullWidth && styles.skeleton_fullWidth,
        className,
        colorClass(),
      )}
      style={{ height }}>
      {children}
    </div>
  );
};
