import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-justanother-router';
import { formatAmount, Typography } from 'dekube-ui-kit';

import { ChartMini, decimateData, Skeleton } from '@/shared';
import { RouterName } from '@app/router';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.scss';

const decimate = 3;

export const MarketChart: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getHistoryTrading({ period: 'week' }),
    queryKey: [QueryKey.HistoryTrading],
  });

  const formattedData = useMemo(
    () =>
      (data?.trading || []).reduce<[number, number, number][]>((acc, el) => {
        if (el['']) {
          const tradeItem = el[''].find(
            (el) =>
              el['first-token'] === 'usdt' && el['second-token'] === 'gaus',
          );

          if (tradeItem) {
            acc.push([
              +el.timestamp,
              +tradeItem['close-price'],
              +tradeItem['turnover'],
            ]);
          }
        }

        return acc;
      }, []),
    [data],
  );

  const labels = useMemo(
    () =>
      decimateData(
        formattedData.map(([timestamp]) => timestamp * 1000),
        decimate,
      ),
    [formattedData],
  );
  const datasets = useMemo(
    () =>
      isLoading
        ? []
        : [
            {
              data: decimateData(
                formattedData.map(([, price]) => 1 / price),
                decimate,
              ),
            },
          ],
    [formattedData, isLoading],
  );

  const price = 1 / (formattedData.at(-1)?.[1] || 1);
  const marketcap = formattedData.reduce((acc, el) => (acc += el[2]), 0);

  return (
    <div className={styles.marketChart}>
      <Typography
        className={styles.marketChart__title}
        color="gray-400"
        variant="m-medium">
        {t('titles.statisticOverview')}
      </Typography>
      <div className={styles.marketChart__head}>
        <div className={styles.marketChart__headItem}>
          <Typography color="gray-400" variant="m-medium">
            {t('labels.price')}
          </Typography>
          <Typography variant="xl-regular">
            {isLoading ? (
              <Skeleton>NNNNN</Skeleton>
            ) : (
              `$${formatAmount(price || 0)}`
            )}
          </Typography>
        </div>
        <div className={styles.marketChart__headItem}>
          <Typography color="gray-400" variant="m-medium">
            {t('labels.cap')}
          </Typography>
          <Typography variant="xl-regular">
            {isLoading ? (
              <Skeleton>NNNNN</Skeleton>
            ) : (
              `$${formatAmount(marketcap || 0)}`
            )}
          </Typography>
        </div>
      </div>
      <Link to={RouterName.Stats}>
        <ChartMini datasets={datasets} labels={labels} />
      </Link>
    </div>
  );
};
