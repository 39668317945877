import { FC, useMemo } from 'react';

import { CopyHash, EasyTable } from '@/shared';
import { api } from '@shared/libs/api';
import { IEscortSignature, QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { title: 'Key', value: 'key' },
  { title: 'Signature', value: 'signature' },
];

export interface BlockEscortSignaturesProps {
  id: number;
}
export const BlockEscortSignatures: FC<BlockEscortSignaturesProps> = ({
  id,
}) => {
  const { data, isLoading } = useQuery({
    queryFn: () => api.getBlockById({ id }),
    queryKey: [QueryKey.Block, id],
    select: (data) => data['block-data']['escort-signatures'],
  });

  const rowTemplate = (item: IEscortSignature) => ({
    key: <CopyHash hash={item.key} shorted shortLength={8} />,
    signature: <CopyHash hash={item.signature} shorted shortLength={12} />,
    uuid: item.key,
  });

  const tableItems = useMemo(() => (data || []).map(rowTemplate), [data]);

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={3}
    />
  );
};
