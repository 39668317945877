import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-justanother-router';

import { isDigestTransaction } from '@/shared';
import { RouterName } from '@app/router';
import { api } from '@shared/libs/api';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';

export interface IExplorerSearchParams {
  external?: boolean;
}
export const useExplorerSearch = (paramsSearch?: IExplorerSearchParams) => {
  const { t } = useTranslation();
  const { navigate, urlFor } = useNavigate();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<Maybe<string>>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleNavigate = (
    route: RouterName,
    params?: object,
    query?: object,
  ) => {
    if (paramsSearch?.external) {
      setValue('');
      window.open(urlFor(route, params, query), '_blank');
    } else {
      navigate(route, params, query);
    }
  };

  const handleBlockSearch = async (id: number) => {
    const result = await api.getBlockById({ id });

    if (result) {
      return handleNavigate(RouterName.Block, { id });
    } else {
      setError(t('validations.notFoundBlock'));
    }
  };
  const handleTransactionSearch = async (digest: string) => {
    const result = await api.getTransactionByDigest({ digest });

    if (result) {
      return handleNavigate(RouterName.Transaction, { digest });
    } else {
      setError(t('validations.notFoundTransaction'));
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (!Number.isNaN(Number(value)) && Number.parseInt(value, 10)) {
      await handleBlockSearch(+value);
    } else if (isDigestTransaction(value)) {
      await handleTransactionSearch(value);
    } else {
      setError(t('validations.invalidInputSearch'));
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isFocus && event.key === 'Enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFocus, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setValue(event.target.value);
  };

  return {
    error,
    isFocus,
    isLoading,
    onChange: handleChange,
    setIsFocus,
    value,
  };
};
