import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';
import { SearchIcon, Typography } from 'dekube-ui-kit';

import { EasyTable, useModal } from '@/shared';
import { RouterName } from '@app/router';
import { api } from '@shared/libs/api';
import { IWallet, QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const pageSize = 50;

export const WalletsTable: FC = () => {
  const openBalanceModal = useModal('balanceWallet');
  const { navigate } = useNavigate();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      api.getWalletsList({
        page: 1,
        pageSize,
      }),
    queryKey: [QueryKey.WalletsList],
    refetchInterval: 10_000,
  });
  const headers = [
    { hide: true, key: true, value: 'uuid' },
    { title: 'Block ID', value: 'block' },
    { title: 'Wallet', value: 'wallet' },
    { title: 'From count', value: 'fromCount' },
    { title: 'To count', value: 'toCount' },
    { className: 'text-center-table', title: 'Balance', value: 'balance' },
  ];

  const rowTemplate = (item: IWallet) => ({
    balance: (
      <SearchIcon
        className="icon cursor-pointer"
        onClick={() => openBalanceModal({ publicKey: item['wallet'] })}
      />
    ),
    block: (
      <Typography
        className="text-hover-underline cursor-pointer"
        variant="s-regular"
        color="blue-400"
        onClick={() => navigate(RouterName.Block, { id: item['block-id'] })}>
        {item['block-id']}
      </Typography>
    ),
    fromCount: item['transaction-from'],
    toCount: item['transaction-to'],
    uuid: item.wallet,
    wallet: item.wallet,
  });

  const tableItems = useMemo(
    () => (data?.['wallets'] || []).map(rowTemplate),
    [data],
  );

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={pageSize}
    />
  );
};
