import { useState } from 'react';
import { SortType } from 'dekube-ui-kit';

import { TableProps } from '@/shared/ui/Table';

export const useTableSort = <T>(
  initSort?: TableProps<null, T>['initSort'],
  onSort?: TableProps<null, T>['onSort'],
) => {
  const [sortBy, setSortBy] = useState<T | null>(initSort?.key || null);
  const [sort, setSort] = useState<SortType>(initSort?.value || null);

  const handleSort = (curSortBy: T, curSort: SortType) => {
    setSort(curSort);
    setSortBy(curSortBy);
    onSort && onSort(curSortBy, curSort);
  };

  return {
    handleSort,
    setSort,
    setSortBy,
    sort,
    sortBy,
  };
};
