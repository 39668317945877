import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';
import { Typography } from 'dekube-ui-kit';
import moment from 'moment';

import { EasyTable, Headers, useTableSort } from '@/shared';
import { RouterName } from '@app/router';
import { api } from '@shared/libs/api';
import {
  IBlockData,
  IBlockDataSortKeys,
  QueryKey,
  SortKeys,
} from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const headers: Headers<IBlockDataSortKeys>[] = [
  { hide: true, key: true, value: 'key' },
  {
    key: true,
    sortKey: SortKeys.ByBlock,
    sortable: true,
    title: 'Block',
    value: 'block',
  },
  { title: 'Transactions', value: 'tx' },
  {
    sortKey: SortKeys.ByDigest,
    sortable: true,
    title: 'Digest',
    value: 'digest',
  },
  {
    sortKey: SortKeys.ByTimestamp,
    sortable: true,
    title: 'Time',
    value: 'time',
  },
];
const pageSize = 50;

export const BlocksTable: FC = () => {
  const { handleSort, sort, sortBy } = useTableSort<IBlockDataSortKeys>();

  const { navigate } = useNavigate();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      api.getBlocksList({
        page: 1,
        pageSize,
        sortOrder: sort,
        sortedBy: sortBy,
      }),
    queryKey: [QueryKey.BlocksList, sort, sortBy],
    refetchInterval: 10_000,
  });

  const rowTemplate = (item: IBlockData) => ({
    block: (
      <Typography
        className="text-hover-underline cursor-pointer"
        variant="s-regular"
        color="blue-400"
        onClick={() => navigate(RouterName.Block, { id: item.id })}>
        {item.id}
      </Typography>
    ),
    digest: item['block-header-digest'],
    key: item.id,
    time: moment(item.timestamp).format('HH:mm:ss'),
    tx: item['transaction-count'],
  });

  const tableItems = useMemo(
    () => (data?.['block-data'] || []).map(rowTemplate),
    [data],
  );

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={pageSize}
      onSort={(sortBy, sort) => handleSort(sortBy, sort)}
    />
  );
};
