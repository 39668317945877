import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import { Card } from '@/shared';
import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export interface StatsInfoItemProps {
  label: string;
  value: string;
  to?: RouterName;
}
export const StatsInfoItem: FC<StatsInfoItemProps> = ({ label, to, value }) => {
  const { navigate } = useNavigate();

  return (
    <Card
      className={cx(styles.statsInfoItem, to && styles.statsInfoItem_linked)}
      py={1.5}
      px={2}
      onClick={() => (to ? navigate(to) : null)}>
      <Typography
        className={styles.statsInfoItem__label}
        variant="s-regular"
        color="gray-400"
        tag="h4">
        {label}
      </Typography>
      <Typography
        className={styles.statsInfoItem__value}
        variant="xl-medium"
        color="black">
        {value}
      </Typography>
    </Card>
  );
};
