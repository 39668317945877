import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';
import { formatAmount, RightArrowIcon, Typography } from 'dekube-ui-kit';

import {
  CellLink,
  CopyHash,
  EasyTable,
  getAmountByAsset,
  Headers,
  useAssets,
  useTableSort,
} from '@/shared';
import { RouterName } from '@app/router';
import { TransactionsFiltersState } from '@features/Transactions';
import { api } from '@shared/libs/api';
import {
  ITransactionListSortKeys,
  QueryKey,
  SortKeys,
  TransactionListItem,
  TransactionType,
} from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const pageSize = 50;

export interface TransactionsTableProps {
  filters: TransactionsFiltersState;
}
export const TransactionsTable: FC<TransactionsTableProps> = ({ filters }) => {
  const { handleSort, sort, sortBy } = useTableSort<ITransactionListSortKeys>();
  const { navigate } = useNavigate();
  const { getNameByCode } = useAssets();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      api.getTransactionsList({
        page: 1,
        pageSize,
        sortOrder: sort,
        sortedBy: sortBy,
        ...filters,
      }),
    queryKey: [QueryKey.TransactionsList, filters, sort, sortBy],
    refetchInterval: 10_000,
  });
  const headers: Headers<ITransactionListSortKeys>[] = [
    { title: '', value: 'link', width: 40 },
    {
      sortKey: SortKeys.ByBlock,
      sortable: true,
      title: 'Block',
      value: 'block',
      width: 90,
    },
    {
      sortKey: SortKeys.ByTransactionsType,
      sortable: true,
      title: 'Type',
      value: 'type',
    },
    {
      sortKey: SortKeys.ByDigest,
      sortable: true,
      title: 'Digest',
      value: 'digest',
    },
    {
      className: 'text-right-table',
      sortKey: SortKeys.ByTransactionsFrom,
      sortable: true,
      title: 'From',
      value: 'from',
    },
    { title: '', value: 'transfer', width: 48 },
    {
      sortKey: SortKeys.ByTransactionsTo,
      sortable: true,
      title: 'To',
      value: 'to',
    },
    { title: 'Amount', value: 'amount' },
  ];

  const rowTemplate = (item: TransactionListItem<TransactionType>) => {
    const hasAmount =
      'asset' in item.description || 'amount' in item.description;
    const amountData = hasAmount
      ? // @ts-ignore
        getAmountByAsset(item.description?.amount || item.description?.asset)
      : null;

    return {
      amount:
        hasAmount && amountData
          ? `${formatAmount(amountData.amount)} ${amountData.asset || getNameByCode(amountData.code!)}`
          : '-',
      block: (
        <Typography
          className="text-hover-underline cursor-pointer"
          variant="s-regular"
          color="blue-400"
          onClick={() => navigate(RouterName.Block, { id: item['block-id'] })}>
          {item['block-id']}
        </Typography>
      ),
      digest: <CopyHash hash={item.digest} shorted />,
      from:
        'from' in item.description ? (
          <CopyHash hash={item.description.from} shorted />
        ) : (
          '-'
        ),
      link: (
        <CellLink
          to={RouterName.Transaction}
          params={{ digest: item['digest'] }}
          disabled={!item['digest']}
        />
      ),
      to:
        'to' in item.description ? (
          <CopyHash hash={item.description.to} shorted />
        ) : (
          '-'
        ),
      transfer: (
        <RightArrowIcon
          width={20}
          height={20}
          color="var(--gray-600)"
          style={{ opacity: hasAmount ? 1 : 0 }}
        />
      ),
      type: item.description['transaction-type'],
      uuid: item.digest,
    };
  };

  const tableItems = useMemo(
    () => (data?.['transactions'] || []).map(rowTemplate),
    [data],
  );

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={pageSize}
      onSort={(sortBy, sort) => handleSort(sortBy, sort)}
    />
  );
};
