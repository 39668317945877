import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectMenu, type SelectMenuItem } from '@/shared';
import { TimeInterval, TimeIntervalValues } from '@/shared/types';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';

interface TimeIntervalItem extends SelectMenuItem {
  value: TimeIntervalValues;
}

export interface TimeIntervalSelectorProps {
  onChange(value: Maybe<string | number>): void;
}

export const TimeIntervalSelector: FC<TimeIntervalSelectorProps> = ({
  onChange,
}) => {
  const { t } = useTranslation();
  const timeIntervalConfig: TimeIntervalItem[] = Object.values(
    TimeInterval,
  ).map((value) => ({
    title: t(`intervals.${value}`),
    value,
  }));

  const [timeInterval, setTimeInterval] = useState<SelectMenuItem['value']>(
    timeIntervalConfig[0].value,
  );

  const onChangeSelectMenu: TimeIntervalSelectorProps['onChange'] = (value) => {
    if (!value) {
      return;
    }
    setTimeInterval(value);
    onChange(value);
  };

  useEffect(() => {
    onChange(timeInterval);
  }, []);

  return (
    <SelectMenu
      hideCheckIcon
      horizontal
      singleLineText
      value={timeInterval}
      items={timeIntervalConfig}
      onChange={onChangeSelectMenu}
    />
  );
};
