import { SortType } from 'dekube-ui-kit';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';

export type WithCounter<T> = T & { counter: string };
export interface SupportedAssetItem {
  name: string;
  code: string;
  precision: string;
}
export interface SupportedWalletTagItem {
  name: string;
  code: string;
}
export enum TransactionTypeEnum {
  AccessRightContractTransaction = 'AccessRightContractTransaction',
  BlockchainConfigTransaction = 'BlockchainConfigTransaction',
  CAEBGlueTransaction = 'CAEBGlueTransaction',
  CAEBReset = 'CAEBReset',
  CAEBTransaction = 'CAEBTransaction',
  ContractTransaction = 'ContractTransaction',
  MultiSignRegisterGatewayTransaction = 'MultiSignRegisterGatewayTransaction',
  MultiSignRegisterTokenTransaction = 'MultiSignRegisterTokenTransaction',
  MultiSignSetWalletTag = 'MultiSignSetWalletTag',
  MultiSignTransferAssetsTransaction = 'MultiSignTransferAssetsTransaction',
  MultiWalletTransferAssetsTransaction = 'MultiWalletTransferAssetsTransaction',
  RegisterContractTransaction = 'RegisterContractTransaction',
  RegisterNodeTransaction = 'RegisterNodeTransaction',
  RegisterTokenTransaction = 'RegisterTokenTransaction',
  TransferAssetsTransaction = 'TransferAssetsTransaction',
  UnregisterGatewayTransaction = 'UnregisterGatewayTransaction',
  UnregisterNodeTransaction = 'UnregisterNodeTransaction',
  UserDefineTransaction = 'UserDefineTransaction',
  UserTagTransaction = 'UserTagTransaction',
  UserTagValueTransaction = 'UserTagValueTransaction',
}
export interface PaginationParams {
  pageSize: number;
  page: number;
}

export interface SortParams<QuerySortKeys> {
  sortOrder?: SortType;
  sortedBy?: QuerySortKeys | null;
}

export enum SortKeys {
  ByBlock = 'block-id',
  ByDigest = 'previous',
  ByTransactionsType = 'transaction-type',
  ByTimestamp = 'timestamp',
  ByTransactionsFrom = 'wallet-id',
  ByTransactionsTo = 'destination-wallet',
}

export interface GetBlockByIdParams {
  id: number;
}
export interface GetTransactionByIdParams {
  digest: string;
}
export interface GetWalletStateParams {
  publicKey: string;
}
export interface GetTransactionsOnIdParams {
  'code-transaction'?: Maybe<number>;
  'start-transaction'?: Maybe<number>;
}
export interface GetBlockchainStateResult {
  project: string;
  version: string;
  'block-count': string;
  'node-count': string;
  'transaction-count': string;
  'voing-transaction-count': string;
  'pending-transaction-count': string;
  'blockchain-state': string;
  'consensus-round': string;
  'supported-assets': SupportedAssetItem[];
}
export interface GetBlockchainInfoResult {
  project: string;
  version: string;
  'supported-assets': SupportedAssetItem[];
  'supported-transaction-types': Record<TransactionTypeEnum, string>;
  'supported-wallet-tags': SupportedWalletTagItem[];
}
export interface TradeItem {
  'first-token': string;
  'second-token': string;
  'open-price': string;
  'close-price': string;
  'middle-price': string;
  turnover: string;
  'order-count': string;
  'min-price': string;
  'max-price': string;
}
export interface TradeHistory {
  '': '' | TradeItem[];
  timestamp: string;
}
export interface GetHistoryTradingsResult {
  trading: TradeHistory[];
}
export interface INodeItem {
  'public-key': string;
  address: string;
  'node-id': string;
  latitude: string;
  longitude: string;
}
export interface ICountStatItem {
  date: '' | string;
  count: string;
}
export type GetNodesResult = INodeItem[];
export type Period = 'day' | 'week' | 'year';
export interface GetHistoryTradingsParams {
  period: Period;
}
export interface IEscortSignature {
  key: string;
  signature: string;
}
export interface ITransferAsset {
  amount: string;
  code: string;
}
export interface ITransactionAmount {
  to: string;
  amout: string;
  asset: string;
}
export type BooleanWordType = 'yes' | 'no';
export interface IBaseTransactionFields {
  seed: string;
  'transaction-id': string;
  digest: string;
  signature: string;
}
export interface IMultiSignSetWalletTag extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.MultiSignSetWalletTag;
  'parent-transaction'?: IMultiSignRegisterGatewayTransaction;
  threshold: string;
  'tagged-wallet': string;
  'pub-key': string;
  tag: string;
  'threshold-reached': BooleanWordType;
}
export interface IMultiSignRegisterGatewayTransaction
  extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.MultiSignRegisterGatewayTransaction;
  from: string;
  to: string;
  'pub-key': string;
  asset: ITransferAsset;
  'hold-amount': string;
  'parent-transaction'?: IMultiSignRegisterGatewayTransaction;
  type: string;
  precision: string;
  'asset-description': string;
  'external-address': string;
  comission: string;
  'additional-info': string;
  threshold: string;
  'threshold-reached': BooleanWordType;
}
export interface IUserTagValueTransaction extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.UserTagValueTransaction;
  'public-key': string;
  tag: string;
  'tag-value': string;
}
export interface IUserDefineTransaction extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.UserDefineTransaction;
  'public-key': string;
  'message-id': string;
  message: string;
}
export interface IRegisterTokenTransaction extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.RegisterTokenTransaction;
  from: string;
  to: string;
  asset: ITransferAsset[];
  type: string;
  precision: string;
  description: string;
}
export interface IMultiSignTransferAssetsTransaction
  extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.MultiSignTransferAssetsTransaction;
  threshold: string;
  from: string;
  to: string;
  'pub-key': string;
  'parent-transaction'?: IMultiSignTransferAssetsTransaction;
  asset: ITransferAsset[];
  description: string;
  'threshold-reached': BooleanWordType;
}
export interface IMultiSignRegisterTokenTransaction
  extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.MultiSignRegisterTokenTransaction;
  from: string;
  to: string;
  'pub-key': string;
  asset: ITransferAsset;
  'parent-transaction'?: IMultiSignRegisterTokenTransaction;
  type: string;
  precision: string;
  description: string;
  threshold: string;
  'threshold-reached': BooleanWordType;
}
export interface IMultiWalletTransferAssetsTransaction
  extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.MultiWalletTransferAssetsTransaction;
  from: string;
  amounts: ITransactionAmount[];
  description: string;
}
export interface IRegisterNodeTransaction extends IBaseTransactionFields {
  'transaction-type': TransactionTypeEnum.RegisterNodeTransaction;
  'public-key': string;
  address: string;
}

export interface ITransferAssetTransaction extends IBaseTransactionFields {
  'blockchain-id': string;
  'transaction-type': TransactionTypeEnum.TransferAssetsTransaction;
  from: string;
  to: string;
  asset: ITransferAsset[];
  description: '';
}

export type TransactionType =
  | IRegisterNodeTransaction
  | ITransferAssetTransaction
  | IMultiWalletTransferAssetsTransaction
  | IMultiSignRegisterTokenTransaction
  | IMultiSignTransferAssetsTransaction
  | IRegisterTokenTransaction
  | IUserDefineTransaction
  | IUserTagValueTransaction
  | IMultiSignRegisterGatewayTransaction
  | IMultiSignSetWalletTag;

export type ITransactionListSortKeys =
  | SortKeys.ByBlock
  | SortKeys.ByDigest
  | SortKeys.ByTransactionsType
  | SortKeys.ByTransactionsFrom
  | SortKeys.ByTransactionsTo;

export type ITransactionListSort = SortParams<ITransactionListSortKeys>;

export interface IBlockData {
  version: string;
  'previous-block-digest': string;
  'merkle-root': string;
  timestamp: string;
  id: string;
  'transaction-count': string;
  'number-of-signers': string;
  round: string;
  'block-header-digest': string;
  'escort-signatures': IEscortSignature[] | '';
  'fee-transactions': TransactionType[] | '';
  transactions: TransactionType[] | '';
}

export type IBlockDataSortKeys =
  | SortKeys.ByBlock
  | SortKeys.ByDigest
  | SortKeys.ByTimestamp;

export type IBlockDataSort = SortParams<IBlockDataSortKeys>;

export interface IWallet {
  'wallet-id': string;
  'block-id': string;
  wallet: string;
  'transaction-from': string;
  'transaction-to': string;
}
export interface TransactionListItem<T extends TransactionType> {
  description: T;
  type: 'approved';
  digest: string;
  ID: string;
  'block-id': string;
}
export type GetHeaderBlockOnIdResult = WithCounter<{
  'block-data': IBlockData[];
}>;

export type GetBlockByDayStatisticResult = WithCounter<{
  'block-by-day': ICountStatItem[];
}>;
export type GetRoundByDayStatisticResult = WithCounter<{
  'round-by-day': ICountStatItem[];
}>;
export type GetTransactionsResult = WithCounter<{
  transactions: TransactionListItem<TransactionType>[];
}>;
export type GetWalletsResult = WithCounter<{
  wallets: IWallet[];
}>;
export enum WalletTagEnum {
  NoKYC = 'NoKYC',
}
export interface GetWalletStateResult {
  balance: ITransferAsset[];
  freeze: string;
  'additional-info': string;
  tags: WalletTagEnum[];
  'preferred-transaction-id': string;
  exist: string;
}
export interface GetBlockByIdResult {
  'block-data': IBlockData;
}
export interface GetLastBlockResult {
  'current-block-id': string;
}
