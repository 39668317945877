import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import { BlocksTable } from '@entities/Blocks';
import { MainStatsNumbers } from '@widgets/Stats';

import styles from './styles.module.scss';

export const BlocksPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.blocks}>
      <MainStatsNumbers />
      <Card titlePy={2} titlePx={2} title={t('labels.blocks')}>
        <BlocksTable />
      </Card>
    </div>
  );
};
