import { FC, useEffect, useRef, useState } from 'react';
import {
  ColorType,
  createChart as createLightweightChart,
  IChartApi,
  ISeriesApi,
  MouseEventHandler,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';
import moment from 'moment';

import {
  formatLargeNumber,
  formatTickMarks,
  formatValue,
  Skeleton,
} from '@/shared';

import { DatasetType } from '../types';

import { ChartLegend } from '../ChartLegend/ChartLegend';
import { ChartTooltip, ChartTooltipProps } from '../ChartTooltip/ChartTooltip';

import styles from './styles.module.scss';

export interface ChartBarProps {
  labels: number[];
  datasets: DatasetType[];
  hideLegend?: boolean;
  skeletonLoader?: boolean;
}
export const ChartBar: FC<ChartBarProps> = ({
  datasets,
  hideLegend,
  labels,
  skeletonLoader,
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<IChartApi>();
  const [skeletonChart, setSkeletonChart] = useState<IChartApi>();
  const [histogramSeries, setHistogramSeries] =
    useState<ISeriesApi<'Histogram', Time>>();
  const [areaSeries, setAreaSeries] = useState<ISeriesApi<'Histogram', Time>>();
  const [visibleSeries, setVisibleSeries] = useState<boolean[]>([true, true]);
  const [tooltipData, setTooltipData] = useState<ChartTooltipProps>({
    isVisible: false,
    position: { x: 0, y: 0 },
    tooltipHeader: '',
    tooltipItems: [],
  });

  const updateTooltip: MouseEventHandler<Time> = (param) => {
    if (
      param.time &&
      chartContainerRef.current &&
      param.point &&
      tooltipRef.current
    ) {
      const containerRect = chartContainerRef.current.getBoundingClientRect()!;
      const { offsetHeight: tooltipHeight, offsetWidth: tooltipWidth } =
        tooltipRef.current;
      const offsetX = 30;
      const offsetY = 30;

      const x = Math.min(
        param.point.x + offsetX,
        containerRect.width - tooltipWidth,
      );
      const y = Math.min(
        param.point.y + offsetY,
        containerRect.height - tooltipHeight,
      );

      const chartData = param.seriesData.get(areaSeries!);
      const histogramData = param.seriesData.get(histogramSeries!);

      const date = new Date((param.time as number) * 1000);

      setTooltipData({
        isVisible: true,
        position: { x, y },
        tooltipHeader: moment(date).format('D MMMM [в] H:mm')!,
        tooltipItems: datasets.map((dataset, index) => ({
          label: dataset.label || '',
          suffix: dataset.suffix || '',
          value: formatValue(
            // @ts-ignore
            index === 0 ? chartData?.value : histogramData?.value,
          ),
        })),
      });
    } else {
      setTooltipData((state) => ({ ...state, isVisible: false }));
    }
  };

  const createChart = () => {
    if (labels.length === 0 || datasets.length === 0) {
      return;
    }
    chart?.remove();
    skeletonChart?.remove();

    const chartColor = '#f0f2f5';
    const chartPointColor = '#666';
    const opacityColor = '#02977e99';
    const mutedColor = '#00000026';

    const newChart = createLightweightChart(chartRef.current!, {
      crosshair: {
        horzLine: {
          color: mutedColor,
          labelVisible: false,
          visible: false,
        },
        vertLine: {
          color: mutedColor,
          labelVisible: false,
        },
      },
      grid: {
        horzLines: {
          color: chartColor,
        },
        vertLines: {
          color: 'transparent',
        },
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        vertTouchDrag: false,
      },
      height: chartRef.current!.clientHeight,
      layout: {
        background: {
          color: 'transparent',
          type: ColorType.Solid,
        },
        textColor: chartPointColor,
      },
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        allowBoldLabels: false,
        borderVisible: false,
        fixLeftEdge: true,
        fixRightEdge: true,
        minimumHeight: 30,
        tickMarkFormatter: formatTickMarks,
        tickMarkMaxCharacterLength: 13,
      },
      width: chartRef.current!.clientWidth,
    });

    setChart(newChart);

    const histogramData = labels
      .map((label, index) => ({
        time: Math.floor(label / 1000) as UTCTimestamp,
        value: datasets[1]?.data[index],
      }))
      .sort((a, b) => a.time - b.time);

    const newHistogramSeries = newChart?.addHistogramSeries({
      color: opacityColor,
      lastValueVisible: false,
      priceLineVisible: false,
      priceScaleId: 'histogram',
    });

    newHistogramSeries?.setData(histogramData);

    setHistogramSeries(newHistogramSeries);

    const newAreaSeries = newChart?.addHistogramSeries({
      base: 2,
      color: opacityColor,
      priceFormat: {
        formatter: formatLargeNumber,
        type: 'custom',
      },
      priceScaleId: 'right',
    });

    const data = labels
      .map((label, index) => ({
        time: Math.floor(label / 1000) as UTCTimestamp,
        value: datasets[0].data[index],
      }))
      .sort((a, b) => a.time - b.time);

    newAreaSeries?.setData(data);

    setAreaSeries(newAreaSeries);

    newChart.timeScale().fitContent();
  };

  useEffect(() => {
    if (chart && areaSeries && histogramSeries) {
      chart?.subscribeCrosshairMove(updateTooltip);
    }
  }, [chart]);

  const createSkeletonChart = () => {
    chart?.remove();
    skeletonChart?.remove();

    const skeletonColor = '#00000019';

    const newChart = createLightweightChart(chartRef.current!, {
      crosshair: {
        horzLine: { visible: false },
        mode: 0,
        vertLine: { visible: false },
      },
      grid: {
        horzLines: { color: 'transparent', visible: false },
        vertLines: { color: 'transparent', visible: false },
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        vertTouchDrag: false,
      },
      height: chartRef.current!.clientHeight,
      layout: {
        background: { color: 'transparent', type: ColorType.Solid },
        textColor: skeletonColor,
      },
      rightPriceScale: {
        borderVisible: false,
        visible: false,
      },
      timeScale: {
        borderVisible: false,
        minimumHeight: 50,
        visible: false,
      },
      width: chartRef.current!.clientWidth,
    });

    setSkeletonChart(newChart);

    const newAreaSeries = newChart.addHistogramSeries({
      base: 2,
      color: skeletonColor,
    });

    const now = Date.now();
    const skeletonLabels = Array.from(
      { length: 20 },
      (_, i) => now - i * 60 * 1000,
    ).reverse();
    const skeletonDataset = Array.from(
      { length: 20 },
      () => Math.random() * 20 + 10,
    );

    const skeletonData = skeletonLabels.map((label, index) => ({
      time: Math.floor(label / 1000) as UTCTimestamp,
      value: skeletonDataset[index],
    }));

    newAreaSeries.setData(skeletonData);
    newChart.timeScale().fitContent();
  };

  useEffect(() => {
    if (skeletonChart) {
      createChart();
    }
  }, [datasets, skeletonChart]);

  useEffect(() => {
    createSkeletonChart();

    return () => {
      chart?.remove();
      skeletonChart?.remove();
    };
  }, []);

  const handleToggleChart = (index: number) => {
    setVisibleSeries((state) => {
      const newState = [...state];

      newState[index] = !newState[index];

      return newState;
    });
    if (index === 0 && areaSeries) {
      areaSeries.applyOptions({ visible: visibleSeries[index] });
    } else if (index === 1 && histogramSeries) {
      histogramSeries.applyOptions({ visible: visibleSeries[index] });
    }
  };

  return (
    <div ref={chartContainerRef} className={styles.chartBar}>
      {!hideLegend && (
        <ChartLegend
          datasets={datasets}
          colors={['rgb(108, 142, 117)', 'rgba(108, 142, 117, 0.314)']}
          visibleSeries={visibleSeries}
          onToggleChart={handleToggleChart}
        />
      )}

      <div ref={chartRef} className={styles.chartBar__chart}></div>
      {skeletonLoader && chartRef.current && !chart && (
        <Skeleton
          absolute
          height={chartRef.current!.clientHeight}
          bgColor="transparent"
        />
      )}

      <ChartTooltip
        ref={tooltipRef}
        tooltipHeader={tooltipData.tooltipHeader}
        tooltipItems={tooltipData.tooltipItems}
        isVisible={tooltipData.isVisible}
        position={tooltipData.position}
      />
    </div>
  );
};
