import React, { FC, PropsWithChildren, SelectHTMLAttributes } from 'react';
import cx from 'classnames';
import { SortType, Typography } from 'dekube-ui-kit';

import { SortIcon } from '../icons';

import styles from './styles.module.scss';

interface TableTh {
  name?: string;
  width?: number | string;
  sort: SortType;
  sortable?: boolean;
  onSort: (sort: SortType) => void;
}
export const TableTh: FC<
  PropsWithChildren<TableTh & SelectHTMLAttributes<HTMLTableCellElement>>
> = ({
  children,
  className,
  name,
  onClick,
  onSort,
  sort,
  sortable = false,
  width,
  ...reset
}) => {
  const changeSortBy = (e: any) => {
    onClick && onClick(e);
    switch (sort) {
      case 'asc': {
        onSort('desc');
        break;
      }
      case 'desc': {
        onSort(null);
        break;
      }
      default: {
        onSort('asc');
        break;
      }
    }
  };

  return (
    <th
      className={cx(
        styles.table__th,
        name && styles[name],
        className,
        sortable && styles['table__thead--sort'],
      )}
      style={{ width }}
      onClick={changeSortBy}
      {...reset}>
      <Typography
        tag={'div'}
        variant={'s-medium'}
        className={styles.table__cell}>
        <span>{children}</span>
        {sortable && (
          <SortIcon className={styles.uiTable__sortIcon} sort={sort} />
        )}
      </Typography>
    </th>
  );
};
