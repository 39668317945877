import { KeyboardEvent } from 'react';

export const handleNumberInput = (event: KeyboardEvent<HTMLInputElement>) => {
  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
};

export const isDigestTransaction = (digest: string): boolean =>
  !(digest.length < 48 || digest.length > 51);
