import axios from 'axios';

import { API_ENDPOINT, pagination, sort } from '@/shared';
import {
  GetBlockByDayStatisticResult,
  GetBlockByIdParams,
  GetBlockByIdResult,
  GetBlockchainInfoResult,
  GetBlockchainStateResult,
  GetHeaderBlockOnIdResult,
  GetHistoryTradingsParams,
  GetHistoryTradingsResult,
  GetLastBlockResult,
  GetNodesResult,
  GetRoundByDayStatisticResult,
  GetTransactionByIdParams,
  GetTransactionsOnIdParams,
  GetTransactionsResult,
  GetWalletsResult,
  GetWalletStateParams,
  GetWalletStateResult,
  IBlockDataSort,
  IBlockDataSortKeys,
  ITransactionListSort,
  ITransactionListSortKeys,
  PaginationParams,
  TransactionListItem,
  TransactionType,
} from '@shared/libs/types';

class Api {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private request(method: string, params?: Record<string, any>) {
    return axios
      .post(this.baseUrl, {
        id: 3,
        jsonrpc: '2.0',
        method,
        params: params || [],
        version: '1.0',
      })
      .then(({ data }) => data?.result);
  }

  public async getBlockchainState(): Promise<GetBlockchainStateResult> {
    return this.request('get-blockchain-state');
  }

  public async getBlockchainInfo(): Promise<GetBlockchainInfoResult> {
    return this.request('get-blockchain-info');
  }

  public async getNodes(): Promise<GetNodesResult> {
    return this.request('get-nodes');
  }

  public async getBlocksList(
    params: PaginationParams & IBlockDataSort,
  ): Promise<GetHeaderBlockOnIdResult> {
    const { limit, offset } = pagination(params);
    const { sortOrder, sortedBy } = sort<IBlockDataSortKeys>(params);

    return this.request('get-header-blocks-on-id', {
      'add-nodes': 1,
      'count-block': limit,
      offset,
      'sort-order': sortOrder,
      'sorted-by': sortedBy,
    });
  }

  public async getHistoryTrading(
    params: GetHistoryTradingsParams,
  ): Promise<GetHistoryTradingsResult> {
    return this.request('get-history-trading', params);
  }

  public async getBlockByDayStatistic(): Promise<GetBlockByDayStatisticResult> {
    return this.request('get-block-by-day-statistic');
  }

  public async getRoundByDayStatistic(): Promise<GetRoundByDayStatisticResult> {
    return this.request('get-round-by-day-statistic');
  }

  public async getLastBlock(): Promise<GetLastBlockResult> {
    return this.request('get-current-block-id');
  }

  public async getBlockById(
    params: GetBlockByIdParams,
  ): Promise<GetBlockByIdResult> {
    return this.request('get-block-by-id', params);
  }

  public async getTransactionsList(
    params: GetTransactionsOnIdParams & PaginationParams & ITransactionListSort,
  ): Promise<GetTransactionsResult> {
    const { limit, offset } = pagination(params);
    const { sortOrder, sortedBy } = sort<ITransactionListSortKeys>(params);

    return this.request('get-transactions-on-id', {
      'count-transaction': limit,
      offset,
      'sort-order': sortOrder,
      'sorted-by': sortedBy,
      ...params,
    });
  }

  public async getTransactionByDigest(
    params: GetTransactionByIdParams,
  ): Promise<TransactionListItem<TransactionType>> {
    const response = await this.request('get-transactions-on-id', params);

    return response.transactions[0];
  }

  public async getWalletsList(
    params: PaginationParams,
  ): Promise<GetWalletsResult> {
    const { limit, offset } = pagination(params);

    return this.request('get-wallets', {
      'count-wallet': limit,
      'start-wallet': offset,
    });
  }

  public async getWalletState(
    params: GetWalletStateParams,
  ): Promise<GetWalletStateResult> {
    return this.request('get-wallet-state', {
      'public-key': params.publicKey,
    });
  }
}

export const api = new Api(API_ENDPOINT);
