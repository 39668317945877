import { FC } from 'react';
import cx from 'classnames';
import { InfoIcon, Tooltip } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface InfoTooltipProps {
  text: string;
  withOverlay?: boolean;
  className?: string;
}
export const InfoTooltip: FC<InfoTooltipProps> = ({
  className,
  text,
  withOverlay,
}) => (
  <Tooltip
    className={cx(styles.infoTooltip, className)}
    label={text}
    position="top"
    zIndex={10_000}>
    <div
      className={cx(
        styles.infoTooltip__wrap,
        withOverlay && styles.infoTooltip__wrap_overlay,
      )}>
      <InfoIcon className={styles.infoTooltip__icon} />
    </div>
  </Tooltip>
);
