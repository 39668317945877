import { FC, SVGProps } from 'react';
import { SortType } from 'dekube-ui-kit';

export interface SortIconProps {
  sort?: SortType;
}
export const SortIcon: FC<SVGProps<SVGSVGElement> & SortIconProps> = ({
  sort = null,
  ...rest
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
    {...rest}>
    {sort !== 'desc' && (
      <path
        d="M11.8966 10.4294L8.25917 7.09766C8.1902 7.0351 8.09706 7 8 7C7.90294 7 7.8098 7.0351 7.74083 7.09766L4.1034 10.4294C4.05406 10.4776 4.02061 10.5376 4.00693 10.6024C3.99325 10.6673 3.9999 10.7344 4.02611 10.7959C4.05394 10.8566 4.10074 10.9083 4.16061 10.9446C4.22049 10.9809 4.29076 11.0002 4.36257 11H11.6374C11.7092 11.0002 11.7795 10.9809 11.8394 10.9446C11.8993 10.9083 11.9461 10.8566 11.9739 10.7959C12.0001 10.7344 12.0067 10.6673 11.9931 10.6024C11.9794 10.5376 11.9459 10.4776 11.8966 10.4294Z"
        fill="#7D8CAA"
      />
    )}
    {sort !== 'asc' && (
      <path
        d="M11.9739 13.2042C11.9461 13.1435 11.8993 13.0917 11.8394 13.0554C11.7795 13.0191 11.7092 12.9998 11.6374 13H4.36257C4.29076 12.9998 4.22049 13.0191 4.16061 13.0554C4.10074 13.0917 4.05394 13.1435 4.02611 13.2042C3.9999 13.2657 3.99325 13.3328 4.00693 13.3977C4.02061 13.4626 4.05406 13.5226 4.1034 13.5708L7.74083 16.904C7.81036 16.9656 7.90329 17 8 17C8.09671 17 8.18964 16.9656 8.25917 16.904L11.8966 13.5708C11.9459 13.5226 11.9794 13.4626 11.9931 13.3977C12.0067 13.3328 12.0001 13.2657 11.9739 13.2042Z"
        fill="#7D8CAA"
      />
    )}
  </svg>
);
