import { PaginationParams, SortParams } from '@shared/libs/types';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';

export const formatTickMarks = (time: number) => {
  const date = new Date(time * 1000);

  return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
};

export const formatLargeNumber = (value: number) => {
  if (Math.abs(value) >= 1e9) {
    return Math.round(value / 1e9) + 'B';
  }
  if (Math.abs(value) >= 1e6) {
    return Math.round(value / 1e6) + 'M';
  }
  if (Math.abs(value) >= 1e3) {
    return (value / 1e3).toFixed(2) + 'K';
  }
  if (value === 0) {
    return '0';
  }

  return value.toFixed(2);
};

export const formatValue = (value?: Maybe<string>) => {
  if (value === null || value === undefined) {
    return '0';
  }

  const numericValue = Number.parseFloat(value).toFixed(3);
  const [whole, fraction] = numericValue.split('.');
  const formattedWhole = whole.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return fraction && fraction !== '000'
    ? `${formattedWhole}.${fraction}`
    : formattedWhole;
};

export const pagination = ({ page, pageSize }: PaginationParams) => ({
  limit: pageSize,
  offset: (page - 1) * pageSize,
});

export const sort = <SortKeys>({
  sortOrder,
  sortedBy,
}: SortParams<SortKeys>) => ({
  sortOrder: sortOrder ?? undefined,
  sortedBy: sortOrder && sortedBy ? sortedBy : undefined,
});

export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export const getShortAddress = (
  value: string,
  beforeSymbols = 4,
  afterSymbols = 4,
): string => `${value.slice(0, beforeSymbols)}...${value.slice(-afterSymbols)}`;
