import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'dekube-ui-kit';

import { RouterName } from '@app/router';
import { StatsInfoItem } from '@entities/Stats';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.scss';

export const MainStatsNumbers: FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryFn: () => api.getBlockchainState(),
    queryKey: [QueryKey.BlockchainState],
    refetchInterval: 5000,
  });

  return (
    <div className={styles.mainStatsNumbers}>
      <StatsInfoItem
        to={RouterName.BlocksList}
        label={t('labels.totalBlocks')}
        value={formatAmount(data?.['block-count'] || 0)}
      />
      <StatsInfoItem
        label={t('labels.totalNodes')}
        value={formatAmount(data?.['node-count'] || 0)}
      />
      <StatsInfoItem
        to={RouterName.TransactionsList}
        label={t('labels.totalTransactions')}
        value={formatAmount(data?.['transaction-count'] || 0)}
      />
      <StatsInfoItem
        label={t('labels.consensusRound')}
        value={formatAmount(data?.['consensus-round'] || 0)}
      />
    </div>
  );
};
