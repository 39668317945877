import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import { SPACING } from '@/shared';

import { PropsWithNativeTypes } from 'dekube-ui-kit/dist/cjs/types/src/types';

import styles from './styles.module.scss';

export interface CardProps {
  px?: number;
  py?: number;
  titlePx?: number;
  titlePy?: number;
  bottomPx?: number;
  bottomPy?: number;
  bottomDescription?: string;
  width?: number;
  title?: string;
  height?: number;
  headRightSlot?: JSX.Element;
}
export const Card: FC<
  PropsWithChildren<PropsWithNativeTypes<CardProps, HTMLDivElement>>
> = ({
  bottomDescription,
  bottomPx = 0,
  bottomPy = 0,
  children,
  className,
  headRightSlot,
  height,
  px = 0,
  py = 0,
  style,
  title,
  titlePx = 0,
  titlePy = 0,
  width,
  ...rest
}) => (
  <div
    className={cx(styles.card, className)}
    style={{
      height,
      padding: `${SPACING * py}px ${SPACING * px}px`,
      width,
      ...style,
    }}
    {...rest}>
    {(title || headRightSlot) && (
      <div
        className={styles.card__head}
        style={{ padding: `${SPACING * titlePy}px ${SPACING * titlePx}px` }}>
        {title && (
          <Typography
            className={styles.card__title}
            variant="m-medium"
            color="gray-400">
            {title}
          </Typography>
        )}
        {headRightSlot && (
          <div className={styles.card__headRight}>{headRightSlot}</div>
        )}
      </div>
    )}
    {children}
    {bottomDescription && (
      <div
        className={styles.card__bottom}
        style={{
          padding: `${SPACING * bottomPy}px ${SPACING * bottomPx}px`,
        }}>
        <Typography variant="s-medium" color="gray-400">
          {bottomDescription}
        </Typography>
      </div>
    )}
  </div>
);
